@import "../../utils/styles/variables";

footer {
	background-color: $backgroundDark;
	padding-bottom: 20px;
	#content-wrapper {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
		align-content: stretch;
		padding: 0 50px 0 50px;

		.social-media {
			margin: auto;
			margin-top: 20px;
			width: auto;
			gap: $gap-size;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			align-content: stretch;
	
			a {
				position: relative;
				text-decoration: none;
				display: flex;
				justify-content: center;
				align-items: center;
				width: $icon-size;
				aspect-ratio: 1;
				font-size: 1.5rem;
				color: $textLight;
				background-color: $linkBackground;
				border-radius: 50%;
				--fill: 0;
		
				&:hover {
					color: $link;
					--fill: 360deg;
				}
		
				&::after {
					position: absolute;
					z-index: -1;
					content: "";
					border-radius: inherit;
					inset: -3px;
					background: conic-gradient($link var(--fill), transparent var(--fill));
					transition: background 1s ease-in-out;
				}
			}
		}
	
		p {
			color: $textLight;
			font-size: 1.5rem;
			margin: 0;
		}
	}

}

@media (max-width: $media-query-extra-large) {
	footer {
		padding-bottom: 15px;
	}
}

@media (max-width: $media-query-large) {
	footer {
		padding-bottom: 15px;
	}
}

@media (max-width: $media-query) {
	footer {
		padding-bottom: 10px;
	}
}

@media (max-width: $media-query-small) {
	footer {
		padding-bottom: 5px;
	}

	.social-media {
		a {
			width: 2.5rem;
			font-size: 1.2rem;
		}
	}

	p {
		font-size: 1.2rem;
	}
}

@media (max-width: $media-query-smallest) {
	.social-media {
		a {
			width: 2rem;
			font-size: 1rem;
		}
	}

	p {
		font-size: 1rem;
	}
}