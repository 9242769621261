@import "../../utils/styles/variables";

.git-grid {
  padding: $padding-large;
  text-align: center;

  h2 {
    font-size: $font-size-title;
    margin-bottom: $margin-large;
    color: $textPrimary;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: $gap-size;

    p {
      color: $textLight;
      background-color: $backgroundDark;
      padding: $padding;
      border-radius: $border-radius;
      box-shadow: 0 0 10px $shadow;
      margin: auto;
    }
  }

  .grid-item {
    background-color: $backgroundPrimary;
    padding: $padding;
    border-radius: $border-radius;
    box-shadow: 0 0 10px $shadow;
  }
}
