@import "../../utils/styles/variables";

header {
  background-color: $backgroundDark;
	padding-bottom: 20px;

  #content-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: stretch;
    padding: 20px 50px;
    margin: auto;
    width: 80%;
    
    div {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      align-content: stretch;
      gap: 10px;
      cursor: default;
      user-select: none;
  
      h1 {
        color: $textLight;
        margin: 0;
      }
  
      p {
        color: $textLight;
        margin: 0;
      }
  }

  }

  nav {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: stretch;

    ul {
      list-style: none;
      display: flex;
      padding: 0;

      a {
        color: $link;
        font-weight: 500;
        text-decoration: none;
        font-size: 20px;
        padding: 5px 20px 5px 20px;
        margin: 0;
        &.selected {
          color: $linkSelected;
          text-decoration: underline;
        }

        &:hover {
          color: $linkSelected;
          text-decoration: underline;
        }
      }
    }
  }

  button {
    color: $textLight;
    font-weight: 500;
    font-size: 20px;
    background-color: $link;
    border: none;
    border-radius: 20px;
    padding: 5px 20px 5px 20px;
    cursor: pointer;

    p {
      margin: 0;
      display: inline;
    }

    &:hover {
      background-color: $linkSelected;
    }
  }
}



.contact-modal {
  // Centrage de la modale
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.6);
  border-radius: 1rem;
  padding: 2rem;
  z-index: 1000;
  width: 80%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  // Ajout de la police de caractères
  font-weight: "400";
  font-size: "16px";
  color: "#1A1A1A";
  text-align: center;

  // Ajout de la boite d'ombre
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  h2 {
    color: $textLight;
    margin: 0;
  }

  .contactModal-content {
    background-color: $backgroundDark;
    color: $textLight;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    
    .close-button {
      color: $textLight;
      float: right;
      font-size: 28px;
      font-weight: bold;

      &:hover,
      &:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }  

  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  
    h2 {
      color: $textSecondary;
    }
  
    .form-group {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
  
      label {
        font-weight: bold;
        color: $textLight;
      }
  
      input,
      textarea {
        padding: 0.5rem;
        border: 1px solid $textSecondary;
        border-radius: 5px;
        font-size: 1rem;
      }
  
      textarea {
        resize: vertical;
        min-height: 100px;
      }
    }
  
    .buttons {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-evenly;
      align-items: center;
      align-content: stretch;
      gap: 1rem;
    }
  
    .submit-button,
    .copy-button,
    .close-button {
      padding: 0.5rem 1rem;
      border: none;
      border-radius: 5px;
      background-color: $link;
      color: $textLight;
      font-size: 1rem;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: $linkSelected;
      }
    }
  
    .copy-button {
      margin-top: 1rem;
    }
  
    .close-button {
      background-color: $error;
    }
  }
}


.copy-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .copy-button {
    color: $textLight;
    background-color: $linkBackground;
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 15px 0;
  }

  .copy-message {
    position: absolute;
    top: -30px;
    background-color: $link;
    color: $textLight;
    padding: 0.5rem;
    border-radius: 5px;
    font-size: 0.9rem;
    opacity: 0;
    animation: fadeInOut 2s forwards;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media screen and (max-width: 1264px) {
  header {
    nav {
      display: none;
    }
  }
}

@media screen and (max-width: 800px) {
  header {
    nav {
      display: none;
    }
  }
}

@media screen and (max-width: 480px) {
  header {
    margin: 0 0 20px 0;

    #content-wrapper {
      flex-direction: column;
      padding: 0;
      gap: 10px;
    }

    h1 {
      font-size: 1.5rem;
    }
  }
}
