@import "../../utils/styles/variables";

.project-card { // Carte du projet
  position: relative;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 2px solid transparent;
  padding: 0 0 30px;
  user-select: none;

  // Effet de survol
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    border: 2px solid $link;
  }
  
  // Image du projet
  .project-thumbnail {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 1rem 1rem 0 0;
  }

  .open-modal-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: $linkSecondary;
    text-decoration: none;
    padding: 0.5rem 1rem;
    border: 1px solid $linkSecondary;
    border-radius: 0.25rem;
    transition: all 0.3s ease;
    font-weight: 500;
    font-size: 1.1rem;
    background-color: $linkBackground;
    cursor: pointer;

    margin: auto;

    &:hover {
      background: $backgroundDark;
      color: $textLight;
    }
  }

  .project-info {
    padding:  1.5rem;

    h3 {
      color: $textLight;
      font-size: 1.5rem;
      margin: 0;
      margin-bottom: 1rem;
    }

    p {
      color: $textLight;
      margin-bottom: 1.5rem;
      line-height: 1.5;
    }

    .tags {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      margin-bottom: 1.5rem;

      .tech-tag {
        background: $backgroundDark;
        color: $textLight;
        padding: 0.4rem 0.8rem;
        border-radius: 0.25rem;
        font-size: 0.9rem;
      }
    }

    .type-time {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 1.5rem;

      span {
        background-color: $backgroundDark;
        color: $textLight;
        padding: 0.4rem 0.8rem;
        border-radius: 0.25rem;
        font-size: 0.9rem;
      }
    }

    .project-links {
      display: flex;
      margin: auto;
      gap: 1rem;
      justify-content: center;
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);

      a {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: $link;
        text-decoration: none;
        padding: 0.5rem 1rem;
        border: 1px solid $link;
        border-radius: 0.25rem;
        transition: all 0.3s ease;
        font-weight: 500;

        &:hover {
          background: $link;
          color: $textLight;
        }

        i {
          font-size: 1.1rem;
        }
      }
    }
  }
}

.project-modal { // Modale
  // Centrage de la modale
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.6);
  border-radius: 1rem;
  padding: 2rem;
  z-index: 1000;
  width: 80%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  // Ajout de la boite d'ombre
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  h2 {
    // Couleur du texte
    color: $textLight;
    font-size: 2rem;

    // Placement du texte	
    margin-bottom: 1rem;
    text-align: center;
  }

  p {
    // Couleur du texte
    color: $textLight;

    // Placement du texte
    margin-bottom: 1.5rem;
    line-height: 1.5;
    text-align: center;
  }

  .project-details {
    // Espacement
    margin-bottom: 1.5rem;

    .description {
      h3 {
        // Couleur du texte
        color: $textLight;

        // Taille de la police
        font-size: 1.5rem;

        // Espacement
        margin-bottom: 1rem;
      }

      ul {
        // Espacement
        margin-bottom: 1.5rem;

        li {
          // Couleur du texte
          color: $textLight;

          // Taille de la police
          font-size: 1.2rem;

          // Espacement
          margin-bottom: 0.5rem;

          // Puce
          list-style: none;
        }
      }
    }

    .tags {
      // Espacement
      margin-bottom: 1.5rem;

      // Affichage en ligne
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;

      // Positionnement
      justify-content: center;

      .tech-tag {
        // Couleur de fond
        background: $backgroundDark;

        // Couleur du texte
        color: $textLight;

        // Espacement
        padding: 0.4rem 0.8rem;

        // Arrondi
        border-radius: 0.25rem;

        // Taille de la police
        font-size: 0.9rem;

        // Puce
        list-style: none;
      }
    }
  }

  .project-links {
    display: flex;
    justify-content: center;
    gap: 1rem;

    a {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: $link;
      text-decoration: none;
      padding: 0.5rem 1rem;
      border: 1px solid $link;
      border-radius: 0.25rem;
      transition: all 0.3s ease;
      font-weight: 500;

      &:hover {
        background: $link;
        color: $textLight;
      }

      i {
        font-size: 1.1rem;
      }
    }
  }

  .project-preview-image {

    // Taille de l'image
    width: 100%;
    height: 300px;

    // Centrage de l'image
    object-fit: contain;

    // Arrondi de l'image
    border-radius: 0.5rem;

    // Espacement 
    margin-bottom: 1.5rem;
  }

  button {
    position: relative;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    background: $error;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    color: $textLight;
    font-size: 1.5rem;
    cursor: pointer;
  }
}

@media screen and (max-width: 1024px) {
  .project-card {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .project-card {
    padding-bottom: 20px;
  }

  .type-time {
    flex-direction: column;
  }
}

@media screen and (max-width: 480px) {
  .project-card {
    padding-bottom: 20px;
  }
}