@import "../../utils/styles/variables.scss";

#projects {
	.filters {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1rem;
		margin-bottom: 2rem;

		.filters-buttons {
			display: flex;
			gap: 1rem;
            
			button {
				padding: 0.5rem 1rem;
				border: 2px solid transparent;
				border-radius: 0.5rem;
				background: transparent;
				color: $textFilter;
				background-color: $backgroundFilter;
				font-size: 1rem;
				cursor: pointer;
				transition: all 0.3s ease;
				font-weight: bold;

				&:hover {
					background: $link;
					transform: translateY(-2px);
					box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
					border: 2px solid $link;
				}

				&.active {
					background: $link;
					border: 2px solid $link;
				}
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	#projects {
		.filters {
			h2 {
				font-size: 1.25rem;
			}

			.filters-buttons {
				flex-direction: column;
				gap: 0.5rem;

				button {
					padding: 0.5rem 1rem;
					font-size: 0.9rem;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	#projects {
		.filters {
			h2 {
				font-size: 1.25rem;
			}

			.filters-buttons {
				flex-direction: column;
				gap: 0.5rem;

				button {
					padding: 0.5rem 1rem;
					font-size: 0.9rem;
				}
			}
		}
	}
}

@media screen and (max-width: 480px) {
	#projects {
		.filters {
			h2 {
				font-size: 1.25rem;
			}

			.filters-buttons {
				flex-direction: column;
				gap: 0.5rem;

				button {
					padding: 0.5rem 1rem;
					font-size: 0.9rem;
				}
			}
		}
	}
}