// How to use : import 'utils/styles/_variables.scss';
// ------------------------------------------------------------------------------------------------------------

// Colors
$textPrimary: #707070;
$textSecondary: #424242;
$textLight: white;
$textDark: #232323;

// Background
$backgroundPrimary: #F9F9FC;
$backgroundSecondary: #E5E5E5;
$backgroundDark: #424242;
$backgroundDarkSecondary: #232323;

// Filter
$textFilter: #F9F9FC;
$backgroundFilter: #00000080;

// Link
$link: #36E052;
$linkSecondary: white;
$linkHover: #57B066;
$linkSelected: #57B066;
$linkBackground: #232323;

// Error
$error: #FF6060;

// Success
$success: #36E052;

// Warning
$warning: #FFD700;

// Border
$border: #E5E5E5;

// Shadow
$shadow: #00000080;

// Font
$fontPrimary: 'Roboto', sans-serif;
$fontSecondary: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

// Font size
$font-size: 1rem;
$font-size-small: 0.8rem;
$font-size-large: 1.2rem;
$font-size-title: 4rem;

// Font weight
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;

// Padding
$padding: 10px;
$padding-small: 5px;
$padding-large: 20px;

// Margin
$margin: 10px;
$margin-small: 5px;
$margin-large: 20px;

// Border radius
$border-radius: 5px;

// Transition
$transition: 0.3s;

// Media queries
$media-query-extra-large: 1200px;
$media-query-large: 992px;
$media-query: 768px;
$media-query-small: 576px;
$media-query-smallest: 480px;

// Other
$icon-size: 3rem;
$gap-size: 10px;