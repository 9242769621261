@import "../../utils/styles/variables";

#about {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  padding: 20px;

  h2 {
    font-size: 3rem;
    margin-bottom: 1rem;
    text-align: center;
    color: $textLight;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  p {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 1.5rem;
    text-align: center;
    color: $textDark;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
      color: $textDark;
    }
  }

  #informations {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    grid-gap: 20px;
    margin: 20px auto;

    h2 {
      font-size: 2rem;
      margin-bottom: 1rem;
      color: $textPrimary;
    }

    div {
      background-color: $backgroundSecondary;
      border-radius: 10px;
      padding: 20px;
      margin: 10px;
      width: 300px;
      text-align: center;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      transition: all 0.3s ease;
      border: 2px solid transparent;

      &:hover {
        border: 2px solid green;
      }
    }
  }

  #more-about-me {
    background-color: $backgroundDark;
    padding: $padding-large;
    border-radius: 10px;
    box-shadow: 0 0 10px $shadow;
    text-align: center;
    width: 80%;
    max-width: 1440px;
    margin: auto;
    margin-bottom: 20px;

    .profile-picture {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      margin: $margin-large;
      box-shadow: 0 0 10px $shadow;
      object-fit: cover;
    }

    h3 {
      font-size: $font-size-title;
      margin: $margin;
      color: $textLight;
    }

    p {
      font-size: $font-size;
      margin: $margin;
      color: $textLight;
    }

    .cv-link {
      display: inline-block;
      padding: $padding;
      background-color: $link;
      color: $textLight;
      text-decoration: none;
      border-radius: $border-radius;
      transition: background-color $transition;

      &:hover {
        background-color: $linkSelected;
      }
    }
  }

  #dropdowns {
    padding: 0 100px 0 100px;
  }
}

@media (max-width: 992px) {
  #about {
	#more-about-me {
	  padding: $padding;
	}

	#informations {
	  grid-template-columns: 1fr;
	}
  }
}

@media (max-width: 768px) {
  #about {
    #more-about-me {
      padding: $padding;
	  margin: 0;
    }

	#informations {
	  grid-template-columns: 1fr;
	}
  }
}

@media (max-width: 576px) {
  #about {
    #more-about-me {
      padding: $padding-small;
      margin-bottom: 0;
    }
  }
}

@media (max-width: 480px) {
  #about {
    #more-about-me {
      padding: $padding-small;
      margin-bottom: 0;
    }

	#informations {
	  padding: 0;
	  width: auto;
	  margin: 20px 0;
	}
  }
}
