@import "../../utils/styles/variables";

main {
  #home {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $padding-large;
    text-align: center;

    h1 {
      font-size: $font-size-title;
      color: $textLight;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }

  }
}

@media (max-width: $media-query) {
  #home {
    padding: $padding;
  }
}

@media (max-width: $media-query-small) {
  #home {
    padding: $padding-small;
  }
}

@media (max-width: $media-query-smallest) {
  #home {
    padding: $padding-small;
  }
}
