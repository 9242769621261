@import "../../utils/styles/variables.scss";

#projects {
  .projects-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    padding: 2rem;
  }

  h2:not(.popup) {
    font-size: 3rem;
    margin-bottom: 1rem;
    text-align: center;
    color: $textLight;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  h3 {
    color: $textLight;
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
}

@media screen and (max-width: 1024px) {
  #projects {
    .projects-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (max-width: 768px) {
  #projects {
    .projects-grid {
      grid-template-columns: 1fr;
    }
  }
}

@media screen and (max-width: 480px) {
  #projects {
    .projects-grid {
      padding: 1rem;
    }

    h2 {
      font-size: 2rem;
    }
  }
}