@import "./utils/styles/variables";

body {
	margin: auto;
	background-image: url("../public/images/wallpaper.jpg");
	background-size: cover;
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-position: center;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	width: 100%;
	
	#separator {
		background-color: $backgroundDark;
		margin: 0 auto;
		text-align: center;
		padding: 40px;
		border-radius: 10px;
		box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
		transition: all 0.3s ease;
		cursor: default;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: auto;
		width: fit-content;

		h2 {
			color: $textLight;
			font-size: 2rem;
			font-weight: $font-weight-bold;
			transition: all 0.3s ease;
		}

		ul {
			padding: 10px 10px;
			width: max-content;
			margin: 0 auto;
			color: $textLight;
			transition: all 0.3s ease;

			li {
				display: inline;
				padding: 5px 15px;
				margin: 0 10px;
				color: $textLight;

				background-color: $backgroundDarkSecondary;
				border-radius: 10px;
				box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
				transition: all 0.3s ease;
			}
		}
	}
}

#root {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

#content-wrapper {
	max-width: 1440px;
	margin: 0 auto;
}

.app-wrapper {
	display: flex;
	flex-direction: column;
	flex: 1;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $fontPrimary;
}

p, a, span, label, input, button {
	font-family: $fontSecondary;
}

.no-scroll {
  overflow: hidden;
}

main {
	flex: 1;
	max-width: 1440px;
	margin: 60px auto;
}

@media (max-width: 1024px) {
	main {
		margin: auto;
		width: 90%;

		#separator {
			ul {
				display: grid;
			grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
			justify-content: center;
			align-items: center;
			gap: 10px;
			}
		}
	}
}

@media (max-width: 768px) {
	main {
		margin: auto;
		width: 85%;
	}
}

@media (max-width: 480px) {
	main {
		margin: auto;
		width: 95%;
	}
}